import React, { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Login from "./Login";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

// Logo component
const Logo = () => (
  <Typography
    variant="h6"
    component="div"
    sx={{ display: "flex", alignItems: "center" }}
  >
    <RouterLink to="/" aria-label="Home">
      <img
        src={require("../logo.webp")}
        alt="Uutislauta logo"
        style={{ width: 120, height: "auto" }}
        loading="lazy"
      />
    </RouterLink>
  </Typography>
);

// Profile button component
const ProfileButton = memo(({ onClick }) => (
  <Button
    component={RouterLink}
    to="/profile"
    variant="text"
    color="inherit"
    startIcon={
      <span role="img" aria-label="Profile">
        👤
      </span>
    }
    onClick={onClick}
    sx={{ textTransform: "none" }}
  >
    Profile
  </Button>
));

ProfileButton.displayName = "ProfileButton";

const Header = ({ menuOpen, setMenuOpen }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const APP_BAR_HEIGHT = "64px";
  const MOBILE_MENU_Z_INDEX = 1400;

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  return (
    <AppBar position="sticky" color="default">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: APP_BAR_HEIGHT,
        }}
      >
        <Logo />

        {/* Desktop Navigation - Single instance */}
        {isDesktop && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {user ? <ProfileButton /> : <Login />}
          </Box>
        )}

        {/* Mobile Menu Toggle */}
        {!isDesktop && (
          <IconButton
            edge="end"
            color="inherit"
            onClick={toggleMenu}
            aria-label={menuOpen ? "Close menu" : "Open menu"}
            aria-expanded={menuOpen}
            aria-controls="mobile-menu"
          >
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        )}
      </Toolbar>

      {/* Mobile Menu */}
      {!isDesktop && menuOpen && (
        <Box
          id="mobile-menu"
          sx={{
            position: "absolute",
            top: APP_BAR_HEIGHT,
            left: 0,
            width: "100%",
            backgroundColor: "background.paper",
            zIndex: MOBILE_MENU_Z_INDEX,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 2,
            boxShadow: 3,
          }}
          role="dialog"
          aria-label="Mobile navigation menu"
        >
          {user ? <ProfileButton onClick={toggleMenu} /> : <Login />}
        </Box>
      )}
    </AppBar>
  );
};

Header.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
};

export default memo(Header);
