import { useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import Login from "./Login";
import MessageItem from "./MessageItem";

const Messages = ({ conversationId, articleId }) => {
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [deletingMessageId, setDeletingMessageId] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [finalConversationId, setFinalConversationId] =
    useState(conversationId);
  const [quotedMessages, setQuotedMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!finalConversationId) return;

      try {
        const apiUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8000/api"
            : "/api";

        const response = await fetch(
          `${apiUrl}/conversations/${finalConversationId}/messages/`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setMessages(data.results || []);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [finalConversationId]);

  const handleSendMessage = async () => {
    if (!messageText.trim() || !user) return;

    setSendingMessage(true);

    try {
      const apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8000/api"
          : "/api";

      let currentConversationId = finalConversationId;

      if (!currentConversationId) {
        const token = await user.getIdToken();
        const convResponse = await fetch(
          `${apiUrl}/conversations/create/${articleId}/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!convResponse.ok) {
          throw new Error(
            `Failed to create conversation: ${convResponse.status}`
          );
        }

        const convData = await convResponse.json();
        currentConversationId = convData.id;
        setFinalConversationId(convData.id);
      }

      const token = await user.getIdToken();
      const msgResponse = await fetch(
        `${apiUrl}/conversations/${currentConversationId}/messages/create/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            text: messageText,
            user_name: user.displayName || "Anonymous",
            quoted_messages: quotedMessages.map((m) => m.id),
          }),
        }
      );

      if (!msgResponse.ok) {
        throw new Error(`Failed to send message: ${msgResponse.status}`);
      }

      const newMessage = await msgResponse.json();
      setMessages((prev) => [...prev, newMessage]);
      setMessageText("");
      setQuotedMessages([]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setSendingMessage(false);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    if (!user) return;

    try {
      setDeletingMessageId(messageId);
      const apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8000/api"
          : "/api";

      const token = await user.getIdToken();
      const response = await fetch(`${apiUrl}/messages/delete/${messageId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete message: ${response.status}`);
      }

      setMessages((prev) => prev.filter((msg) => msg.id !== messageId));
    } catch (error) {
      console.error("Error deleting message:", error);
    } finally {
      setDeletingMessageId(null);
    }
  };

  const handleQuote = (message) => {
    setQuotedMessages((prev) => {
      const exists = prev.find((m) => m.id === message.id);
      return exists
        ? prev.filter((m) => m.id !== message.id)
        : [...prev, message];
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#1e1e1e", // Match background
        padding: "16px", // Add some padding for aesthetics
        borderRadius: "8px",
      }}
    >
      {messages.length > 0 ? (
        messages.map((message) => (
          <MessageItem
            key={message.id}
            message={message}
            user={user}
            onQuote={handleQuote}
            onDelete={handleDeleteMessage}
            isDeleting={deletingMessageId === message.id}
          />
        ))
      ) : (
        <Box sx={{ mt: 2 }}>
          {!user && (
            <div>No messages available. Login to start a conversation.</div>
          )}
          {!user && (
            <Box sx={{ mt: 2 }}>
              <Login />
            </Box>
          )}
        </Box>
      )}

      {user && (
        <>
          {quotedMessages.length > 0 && (
            <Box
              sx={{
                mb: 2,
                p: 1,
                borderLeft: "4px solid #888",
                borderRadius: 1,
                backgroundColor: "#2d2d2d", // Slight contrast for quoted messages
              }}
            >
              <strong>Quoting:</strong>
              {quotedMessages.map((m) => (
                <Box
                  key={m.id}
                  sx={{
                    borderLeft: "3px solid #555",
                    pl: 1,
                    mt: 1,
                    mb: 1,
                    fontStyle: "italic",
                    color: "#e0e0e0",
                  }}
                >
                  <strong>{m.user_name}</strong>: {m.text}
                </Box>
              ))}
              <Button
                size="small"
                onClick={() => setQuotedMessages([])}
                sx={{
                  mt: 1,
                  color: "#90caf9", // Consistent button styling
                }}
              >
                Clear Quotes
              </Button>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
              mt: 2,
            }}
          >
            <TextField
              fullWidth
              label="Type your message..."
              variant="outlined"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              disabled={sendingMessage}
              sx={{
                backgroundColor: "#252525", // Dark background for input field
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#444444", // Border color for input
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#90caf9", // Border color on hover
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
              disabled={sendingMessage}
              sx={{
                backgroundColor: "#4a4fe4", // Primary button color
                "&:hover": {
                  backgroundColor: "#3a3fce", // Hover effect for button
                },
              }}
            >
              {sendingMessage ? "Sending..." : "Send"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Messages;
