import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9", // Light blue
      dark: "#5d99c6",
      light: "#c3fdff",
    },
    secondary: {
      main: "#f48fb1", // Light pink
      dark: "#bf5f82",
      light: "#ffc1e3",
    },
    background: {
      default: "#121212", // Base dark background
      paper: "#1e1e1e", // Slightly lighter for cards/elements
      elevation1: "#252525", // For subtle raised elements
      elevation2: "#2d2d2d", // For more prominent elements
    },
    text: {
      primary: "#e0e0e0",
      secondary: "#a0a0a0",
      disabled: "#6e6e6e",
    },
    divider: "#383838",
    action: {
      active: "#ffffff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
    success: {
      main: "#66bb6a",
    },
    error: {
      main: "#f44336",
    },
    info: {
      main: "#29b6f6",
    },
    warning: {
      main: "#ffa726",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 600,
      letterSpacing: "0em",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
      letterSpacing: "0em",
    },
    h6: {
      fontSize: "1.125rem",
      fontWeight: 500,
      letterSpacing: "0.0075em",
    },
    body1: {
      fontSize: "1rem",
      letterSpacing: "0.00938em",
    },
    body2: {
      fontSize: "0.875rem",
      letterSpacing: "0.01071em",
    },
    subtitle1: {
      fontSize: "1rem",
      letterSpacing: "0.00938em",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "0.875rem",
      letterSpacing: "0.00714em",
      fontWeight: 500,
    },
    caption: {
      fontSize: "0.75rem",
      letterSpacing: "0.03333em",
    },
    button: {
      fontSize: "0.875rem",
      letterSpacing: "0.02857em",
      fontWeight: 500,
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          backgroundColor: "#121212",
          height: "100%",
          margin: 0,
          padding: 0,
          minHeight: "100vh",
          color: "#e0e0e0",
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
            width: 8,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
        },
        "#root": {
          height: "100%",
          minHeight: "100vh",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1a1a1a",
          padding: "16px",
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 8,
          padding: "8px 16px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          backgroundColor: "#4a4fe4", // More vibrant blue for primary buttons
          "&:hover": {
            backgroundColor: "#3a3fce",
          },
        },
        containedSecondary: {
          backgroundColor: "#9c27b0", // Purple for secondary actions
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#7b1fa2",
          },
        },
        text: {
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.05)",
          },
        },
        textPrimary: {
          color: "#90caf9",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1e1e",
          borderRadius: 12,
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "16px 24px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "12px 24px 24px",
          "&:last-child": {
            paddingBottom: 24,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#90caf9",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#2a2a2a",
        },
        deleteIcon: {
          color: "#e0e0e0",
          "&:hover": {
            color: "#ffffff",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#1e1e1e",
          borderRadius: 12,
          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#383838",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#a0a0a0",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: "0.3s",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#252525",
          transition: "background-color 0.2s",
          "&.Mui-focused": {
            backgroundColor: "#2c2c2c",
          },
          "&:hover": {
            backgroundColor: "#292929",
          },
        },
        input: {
          "&::placeholder": {
            color: "#6e6e6e",
            opacity: 1,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#a0a0a0",
          "&.Mui-focused": {
            color: "#90caf9",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
      styleOverrides: {
        root: {
          color: "#90caf9",
          transition: "0.3s",
          "&:hover": {
            color: "#bbdefb",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#6e6e6e",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#90caf9",
          },
        },
        notchedOutline: {
          borderColor: "#444444",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        elevation1: {
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        },
        elevation2: {
          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.2)",
        },
        elevation4: {
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
        elevation8: {
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
        elevation12: {
          boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
        },
        elevation16: {
          boxShadow: "0 16px 32px rgba(0, 0, 0, 0.2)",
        },
        elevation24: {
          boxShadow: "0 24px 48px rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#a0a0a0",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              backgroundColor: "#4a4fe4",
              opacity: 1,
              border: "none",
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#4a4fe4",
            border: "6px solid #fff",
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 26 / 2,
          border: `1px solid #4e4e4e`,
          backgroundColor: "#424242",
          opacity: 1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&.Mui-selected": {
            color: "#90caf9",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#90caf9",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#383838",
          color: "#e0e0e0",
          fontSize: "0.75rem",
        },
        arrow: {
          color: "#383838",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h6",
          subtitle2: "h6",
          body1: "p",
          body2: "p",
        },
      },
    },
  },
});

export default theme;
