import axios from "axios";
import Cookies from "js-cookie"; // Ensure you install this: `npm install js-cookie`

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api"
      : "/api",
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true, // Ensures cookies are sent with the request
});

// Function to get CSRF token
const getCsrfToken = () => Cookies.get("csrftoken"); // Django stores CSRF in a cookie

// Wrapper for CSRF-protected POST requests
export const csrfPost = async (url, data = {}) => {
  try {
    const response = await api.post(url, data, {
      headers: { "X-CSRFToken": getCsrfToken() },
    });
    return response.data;
  } catch (error) {
    console.error("CSRF POST Error:", error);
    throw error;
  }
};

export default api;
