import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Avatar,
} from "@mui/material";

const Profile = () => {
  const { user, loading } = useAuth();
  const [deleteStatus, setDeleteStatus] = useState(null);

  const handleDeleteMessages = async () => {
    if (window.confirm("Are you sure you want to delete all your messages?")) {
      try {
        setDeleteStatus("Deleting...");
        const token = await user.getIdToken();
        const apiUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8000/api"
            : "/api";

        const response = await axios.delete(`${apiUrl}/messages/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setDeleteStatus(
          `Successfully deleted ${response.data.deleted_count} messages`
        );
        setTimeout(() => setDeleteStatus(null), 3000);
      } catch (error) {
        console.error("Error deleting messages:", error);
        setDeleteStatus(
          error.response?.data?.error || "Error deleting messages"
        );
        setTimeout(() => setDeleteStatus(null), 3000);
      }
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      )
    ) {
      try {
        setDeleteStatus("Deleting account...");
        const token = await user.getIdToken();
        const apiUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8000/api"
            : "/api";

        await axios.delete(`${apiUrl}/user/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setDeleteStatus("Account deleted successfully");
        setTimeout(() => {
          setDeleteStatus(null);
          signOut(auth);
        }, 3000);
      } catch (error) {
        console.error("Error deleting account:", error);
        setDeleteStatus(
          error.response?.data?.error || "Error deleting account"
        );
        setTimeout(() => setDeleteStatus(null), 3000);
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Typography variant="body1" color="error">
          Please log in to view your profile.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        User Profile
      </Typography>

      {user.photoURL ? (
        <Avatar
          src={user.photoURL}
          alt="Profile"
          sx={{ width: 100, height: 100, mb: 2, mx: "auto" }}
        />
      ) : (
        <Avatar
          sx={{
            width: 100,
            height: 100,
            mb: 2,
            mx: "auto",
            bgcolor: "#4a90e2",
            fontSize: "2rem",
          }}
        >
          {user.displayName?.charAt(0) || "A"}
        </Avatar>
      )}

      <Typography variant="body1" sx={{ mb: 1 }}>
        <strong>Name:</strong> {user.displayName || "Anonymous"}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        <strong>Email:</strong> {user.email}
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          sx={{ width: "100%" }}
        >
          Logout
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDeleteMessages}
          sx={{ width: "100%" }}
        >
          Delete All Messages
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={handleDeleteAccount}
          sx={{ width: "100%" }}
        >
          Delete Account
        </Button>
      </Box>

      {deleteStatus && (
        <Typography
          variant="body1"
          sx={{
            mt: 2,
            color: deleteStatus.includes("Error")
              ? "error.main"
              : "success.main",
          }}
        >
          {deleteStatus}
        </Typography>
      )}
    </Box>
  );
};

export default Profile;
