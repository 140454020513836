import { Box, Button } from "@mui/material";

const MessageItem = ({ message, user, onQuote, onDelete, isDeleting }) => {
  return (
    <Box
      sx={{
        marginBottom: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        border: "1px solid #444444",
        borderRadius: "8px",
        padding: "8px",
        backgroundColor: "#2d2d2d",
      }}
    >
      {/* --- BLOCK 1: Renders Quotes (KEEP THIS) --- */}
      {message.quoted_messages?.length > 0 && (
        <Box
          sx={{
            padding: 1,
            borderLeft: "4px solid #888",
            fontStyle: "italic",
            color: "#a0a0a0",
            marginBottom: 1, // Optional: Add some space below the quote block
          }}
        >
          {message.quoted_messages.map((qm) => (
            // Ensure your API returns user_name and text for quoted messages
            <div key={qm.id}>
              <strong>{qm.user_name ?? "Unknown User"}</strong>:{" "}
              {qm.text ?? "(message text missing)"}
            </div>
          ))}
        </Box>
      )}

      {/* --- Container for Author Name + Message Text --- */}
      <Box sx={{ color: "#e0e0e0" }}>
        <strong>{message.user_name}</strong> {/* Author of the main message */}
        {/* --- REMOVED REDUNDANT QUOTE BLOCK --- */}
        {/* Display the main message text directly */}
        {/* Using paragraph adds default margins, adjust if needed */}
        <p style={{ margin: 0, marginTop: "4px" }}>{message.text}</p>{" "}
        {/* Text of the main message */}
      </Box>

      {/* --- Buttons (Quote/Delete) --- */}
      {user && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
            marginTop: 1,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            size="small" // Make buttons less intrusive
            onClick={() => onQuote(message)}
            sx={{ fontSize: "0.75rem" }}
          >
            Quote
          </Button>
          {/* Ensure message.user_id or similar is compared if user_name isn't unique/reliable */}
          {message.user_name === user.displayName && (
            <Button
              variant="outlined"
              color="error"
              size="small" // Make buttons less intrusive
              onClick={() => onDelete(message.id)}
              sx={{ fontSize: "0.75rem" }}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MessageItem;
