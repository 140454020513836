import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { csrfPost } from "../api/api";
import Conversation from "./Conversation";
import {
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  CircularProgress,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Divider,
  Container,
  Paper,
  Chip,
  useTheme,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import FilterListIcon from "@mui/icons-material/FilterList";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/"
    : "/api/";

const ArticleList = () => {
  const theme = useTheme();
  const [articles, setArticles] = useState([]);
  const [categoryGroups, setCategoryGroups] = useState([]);
  const [selectedCategoryGroup, setSelectedCategoryGroup] = useState("");
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPaywall, setShowPaywall] = useState(
    JSON.parse(localStorage.getItem("showPaywall")) || false
  );
  const loadMoreRef = useRef(null);
  const lastFetchedPage = useRef(null);

  useEffect(() => {
    localStorage.setItem("showPaywall", JSON.stringify(showPaywall));
  }, [showPaywall]);

  const fetchArticles = useCallback(
    async (pageNum, reset = false) => {
      if (loading || lastFetchedPage.current === pageNum) return;
      setLoading(true);
      lastFetchedPage.current = pageNum;

      try {
        const params = new URLSearchParams({
          page: pageNum,
          show_paywall: showPaywall,
          ...(selectedCategoryGroup && {
            category_group: selectedCategoryGroup,
          }),
        });

        const response = await axios.get(
          `${API_BASE_URL}?${params.toString()}`
        );

        if (response.data.articles) {
          setArticles((prev) =>
            reset
              ? response.data.articles
              : [...prev, ...response.data.articles]
          );
        } else {
          console.error("No 'articles' field in response:", response.data);
        }

        setCategoryGroups(response.data.category_groups || []);
        setHasNext(response.data.has_next || false);
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoading(false);
      }
    },
    [showPaywall, selectedCategoryGroup, loading]
  );

  useEffect(() => {
    fetchArticles(page, page === 1);
  }, [page, fetchArticles]);

  const handleFilterChange = (newCategoryGroup) => {
    if (newCategoryGroup !== selectedCategoryGroup) {
      setSelectedCategoryGroup(newCategoryGroup);
      setArticles([]);
      setPage(1);
      lastFetchedPage.current = null;
    }
  };

  const handlePaywallToggle = () => {
    setShowPaywall((prev) => !prev);
    setArticles([]);
    setPage(1);
    lastFetchedPage.current = null;
  };

  useEffect(() => {
    if (!hasNext || loading) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading) {
          setPage((prev) => prev + 1);
        }
      },
      { threshold: 0.1 }
    );

    const target = loadMoreRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [hasNext, loading]);

  // New function to fetch updated vote counts for an article
  const fetchArticleVotes = async (articleId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}article_votes/${articleId}/`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching article votes:", error);
      return null;
    }
  };

  // Update article votes in state
  const updateArticleVotes = async (articleId) => {
    const voteCounts = await fetchArticleVotes(articleId);

    if (voteCounts) {
      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article.id === articleId
            ? {
                ...article,
                upvotes: voteCounts.upvotes,
                downvotes: voteCounts.downvotes,
              }
            : article
        )
      );
    }
  };

  const handleVote = async (articleId, type) => {
    const upvoteKey = `upvote_${articleId}`;
    const downvoteKey = `downvote_${articleId}`;

    const hasUpvoted = localStorage.getItem(upvoteKey);
    const hasDownvoted = localStorage.getItem(downvoteKey);

    // Handle unvoting (clicking the same button twice)
    if (
      (type === "upvote" && hasUpvoted) ||
      (type === "downvote" && hasDownvoted)
    ) {
      // Remove the vote from localStorage
      localStorage.removeItem(type === "upvote" ? upvoteKey : downvoteKey);

      try {
        // Send a request to remove the vote
        await csrfPost(`${type}/remove/${articleId}/`);

        // Fetch updated vote counts
        await updateArticleVotes(articleId);
      } catch (error) {
        console.error(`Error removing ${type}:`, error);
        // If the API call fails, reset the localStorage to maintain consistency
        localStorage.setItem(
          type === "upvote" ? upvoteKey : downvoteKey,
          "true"
        );
      }
      return;
    }

    // Don't allow both upvote and downvote
    if (
      (type === "upvote" && hasDownvoted) ||
      (type === "downvote" && hasUpvoted)
    ) {
      // Remove the previous opposite vote
      localStorage.removeItem(type === "upvote" ? downvoteKey : upvoteKey);

      // Set the new vote
      localStorage.setItem(type === "upvote" ? upvoteKey : downvoteKey, "true");

      try {
        // First, remove the previous vote
        await csrfPost(
          `${type === "upvote" ? "downvote" : "upvote"}/remove/${articleId}/`
        );

        // Then cast the new vote
        await csrfPost(`${type}/${articleId}/`);

        // Fetch updated vote counts
        await updateArticleVotes(articleId);
      } catch (error) {
        console.error(`Error switching vote:`, error);
        // Restore the previous vote state if there's an error
        localStorage.removeItem(type === "upvote" ? upvoteKey : downvoteKey);
        localStorage.setItem(
          type === "upvote" ? downvoteKey : upvoteKey,
          "true"
        );
      }
      return;
    }

    // Normal first-time voting
    try {
      await csrfPost(`${type}/${articleId}/`);

      // Save the vote in localStorage
      localStorage.setItem(type === "upvote" ? upvoteKey : downvoteKey, "true");

      // Fetch updated vote counts
      await updateArticleVotes(articleId);
    } catch (error) {
      console.error(`Error ${type}ing article:`, error);
      // Remove from localStorage if the API call fails
      localStorage.removeItem(type === "upvote" ? upvoteKey : downvoteKey);
    }
  };

  const getVoteStatus = (articleId) => {
    const upvoteKey = `upvote_${articleId}`;
    const downvoteKey = `downvote_${articleId}`;
    return {
      hasUpvoted: !!localStorage.getItem(upvoteKey),
      hasDownvoted: !!localStorage.getItem(downvoteKey),
    };
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 4,
          borderRadius: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <FilterListIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Filters</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            gap: 2,
          }}
        >
          <FormControl
            sx={{
              flexGrow: 1,
              minWidth: { xs: "100%", sm: "200px" },
            }}
          >
            <InputLabel id="category-group-label">Category Group</InputLabel>
            <Select
              labelId="category-group-label"
              value={selectedCategoryGroup}
              onChange={(e) => handleFilterChange(e.target.value)}
              label="Category Group"
            >
              <MenuItem value="">Kaikki kategoriaryhmät</MenuItem>
              {categoryGroups.map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={showPaywall}
                onChange={handlePaywallToggle}
                color="primary"
              />
            }
            label="Näytä maksumuurin takana olevat"
          />
        </Box>
      </Paper>

      <Typography variant="h4" gutterBottom sx={{ fontWeight: 600, mb: 3 }}>
        Tuoreimmat uutiset
      </Typography>

      {articles.length === 0 && loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 5 }}>
          <CircularProgress size={48} />
        </Box>
      ) : (
        <Box>
          {articles.map((article) => {
            // Get vote status for each article
            const { hasUpvoted, hasDownvoted } = getVoteStatus(article.id);

            return (
              <Card
                key={article.id}
                sx={{
                  mb: 3,
                  border: "1px solid",
                  borderColor: "transparent",
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    borderColor: theme.palette.primary.main,
                    transform: "translateY(-2px)",
                    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <CardContent sx={{ pb: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      mb: 1,
                    }}
                  >
                    <Chip
                      label={article.source}
                      size="small"
                      sx={{
                        backgroundColor: theme.palette.background.elevation1,
                        borderRadius: 1,
                        mb: 1,
                        "& .MuiChip-label": {
                          fontWeight: 500,
                        },
                      }}
                    />

                    {article.paywall && (
                      <Chip
                        icon={<LockIcon fontSize="small" />}
                        label="Paywall"
                        size="small"
                        sx={{
                          backgroundColor: theme.palette.warning.dark,
                          color: "white",
                          borderRadius: 1,
                          "& .MuiChip-icon": {
                            color: "white",
                          },
                        }}
                      />
                    )}
                  </Box>

                  <Typography
                    variant="h6"
                    component="a"
                    href={`${API_BASE_URL}r/${article.id}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: theme.palette.primary.main,
                      textDecoration: "none",
                      fontWeight: 500,
                      display: "block",
                      mb: 2,
                      "&:hover": {
                        color: theme.palette.primary.light,
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {article.title}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: theme.palette.text.secondary,
                      fontSize: "0.875rem",
                      mb: 2,
                    }}
                  >
                    <CalendarTodayIcon sx={{ fontSize: "0.875rem", mr: 0.5 }} />
                    <Typography variant="body2">{article.pub_date}</Typography>
                  </Box>

                  <Divider sx={{ my: 1 }} />

                  <Conversation
                    messageCount={article.message_count}
                    conversationId={article.conversation_id}
                    articleId={article.id}
                  />
                </CardContent>

                <CardActions sx={{ pl: 2, pb: 2 }}>
                  <Button
                    startIcon={<ThumbUpIcon />}
                    onClick={() => handleVote(article.id, "upvote")}
                    variant={hasUpvoted ? "contained" : "text"}
                    color={hasUpvoted ? "primary" : "inherit"}
                    size="small"
                    sx={{ mr: 1 }}
                  >
                    {article.upvotes}
                  </Button>
                  <Button
                    startIcon={<ThumbDownIcon />}
                    onClick={() => handleVote(article.id, "downvote")}
                    variant={hasDownvoted ? "contained" : "text"}
                    color={hasDownvoted ? "secondary" : "inherit"}
                    size="small"
                  >
                    {article.downvotes}
                  </Button>
                  <Button
                    startIcon={<VisibilityIcon />}
                    variant="text"
                    color="inherit"
                    size="small"
                  >
                    {article.views}{" "}
                    {/* Assuming views is the field tracking article views */}
                  </Button>
                </CardActions>
              </Card>
            );
          })}
        </Box>
      )}

      {loading && page > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <CircularProgress />
        </Box>
      )}

      {hasNext && (
        <Box
          ref={loadMoreRef}
          sx={{
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        />
      )}
    </Container>
  );
};

export default ArticleList;
