import { useState } from "react";
import Messages from "./Messages";
import { Button, Badge, Box } from "@mui/material";

const Conversation = ({ conversationId, articleId, messageCount }) => {
  const [showMessages, setShowMessages] = useState(false);

  const handleClick = () => {
    setShowMessages((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        mt: 2,
      }}
    >
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          textTransform: "none",
          padding: "6px 16px",
        }}
      >
        <Badge
          badgeContent={messageCount}
          color="primary"
          sx={{ marginRight: 1 }}
        >
          🗨
        </Badge>
        <span>Conversation</span>
      </Button>

      {showMessages && (
        <Box sx={{ width: "100%", mt: 2 }}>
          <Messages
            conversationId={conversationId}
            articleId={articleId}
            showMessages={showMessages}
          />
        </Box>
      )}
    </Box>
  );
};

export default Conversation;
