import React, { useState, useEffect } from "react";
import { auth, googleProvider } from "../firebase";
import { signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
import { Button, CircularProgress, Typography, Box } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

const Login = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Listen for changes in user authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        localStorage.setItem("firebaseToken", user.accessToken); // Store token for API requests
      } else {
        setUser(null);
        localStorage.removeItem("firebaseToken");
      }
      setLoading(false);
    });

    // Clean up the subscription on component unmount
    return () => unsubscribe();
  }, []);

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const idToken = await result.user.getIdToken();
      setUser(result.user);

      const apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8000/api"
          : "/api";

      const response = await fetch(`${apiUrl}/auth/firebase-login/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`, // Add 'Bearer ' prefix
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: idToken }), // Send token in request body
      });

      const data = await response.json();
      console.log("Backend response:", data);
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  // Handle Logout
  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out of Firebase
      setUser(null);
      localStorage.removeItem("firebaseToken"); // Clear the token
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    ); // Show loading spinner while checking auth state
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding={2}
    >
      {user ? (
        <Box textAlign="center">
          <Typography variant="h6" gutterBottom>
            Welcome, {user.displayName}
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoogleSignIn}
          startIcon={<GoogleIcon />}
          sx={{ padding: "10px 20px", fontSize: "16px" }}
        >
          Sign in with Google
        </Button>
      )}
    </Box>
  );
};

export default Login;
